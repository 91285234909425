// MINI CART
getMiniCart();

const triggerMiniCartId = 'js-mini-cart-trigger';
const anchorMiniCartId = 'js-mini-cart-anchor';
const counterMiniCartId = 'js-mini-cart-counter';
const miniCartId = 'js-mini-cart';
const closeMiniCartClass = 'js-close-mini-cart';
const showClass = 'is-shown';
const blockClass = 'is-blocked';
const loadClass = 'on-loading';

(function ($) {
    // Toggle cart on trigger click
    $(document).on('click', '#' + triggerMiniCartId, function (e) {
        // Return on desktop scaled dimensions
        if (window.innerWidth < 1180 || window.innerHeight < 600) {
            hideMiniCart();
            return;
        }
        e.preventDefault();
        e.stopImmediatePropagation();
        toggleMiniCart();
    });

    // Hide cart on trigger click
    $(document).on('click', '.' + closeMiniCartClass, function (e) {
        hideMiniCart();
    });

    // Update cart on 'add to cart' and  'mda_update_minicart' action
    $(document).on('added_to_cart mda_update_minicart', function () {
        getMiniCart();
    });

    // // Hide on scroll
    // $(document).on('scroll', function (e) {
    //     if ($(window).innerWidth() > 576) {
    //         hideMiniCart();
    //     }
    // });

    // Hide on resize
    $(window).on('resize', function (e) {
        hideMiniCart();
    });

    // Hide on click out of cart
    if ($(window).innerWidth() > 576) {
        $(document).on('click', function (e) {
            let $miniCartDiv = $('#' + miniCartId);
            let $miniCartTrigger = $('#' + triggerMiniCartId);

            if (
                !$miniCartTrigger.is(e.target) &&
                !$miniCartDiv.is(e.target) &&
                $miniCartDiv.has(e.target).length === 0
            ) {
                hideMiniCart();
            }
        });
    }

    $(document).on('change', '.js-cart-dropdown-qty', function (e) {
        e.preventDefault();
        e.stopPropagation();

        const $productQtySelect = $(this);

        if ($productQtySelect.is('select')) {
            var $productQtySelectValue = $productQtySelect
                .find('option:selected')
                .val();
        } else {
            var $productQtySelectValue = $productQtySelect.val();
            $('.js-cart-dropdown-qty').addClass('input-is-active');
        }

        $.ajax({
            type: 'post',
            url: base_url + '/wp-json/wc/v3/shop/cart-change-item-qty',
            data: {
                action: 'mda_mini_cart_change_item_qty',
                cart_item_key: $productQtySelect.attr('data-cart-item-key'),
                qty: $productQtySelectValue,
            },
            beforeSend: function () {
                blockMiniCart();
            },
            success: function (response) {
                updateMiniCart(response);
                unblockMiniCart();
                if ($(document).find("[name='update_cart']").length) {
                    _updateCart();
                }
            },
        });
    });

    $(document).on('click', '.js-cart-ajax-remove', function (e) {
        e.preventDefault();
        e.stopPropagation();

        $.ajax({
            type: 'delete',
            url: base_url + '/wp-json/wc/v3/shop/cart-remove-item',
            data: {
                action: 'mda_remove_from_cart',
                cart_item_key: $(this).attr('data-cart-item-key'),
            },
            beforeSend: function () {
                blockMiniCart();
            },
            success: function (response) {
                updateMiniCart(response);
                unblockMiniCart();
            },
        });
    });
})(jQuery);

function getMiniCart() {
    const miniCart = document.querySelector('#js-mini-cart');
    jQuery.ajax({
        type: 'post',
        url: base_url + '/wp-json/wc/v3/shop/cart-get-items',
        data: {
            action: 'mda_get_mini_cart',
        },
        beforeSend: function () {
            blockMiniCart();
            miniCart.classList.add('on-loading');
        },
        success: function (response) {
            updateMiniCart(response);
            unblockMiniCart();
            miniCart.classList.remove('on-loading');
        },
    });
}

function toggleMiniCart() {
    if (jQuery('#' + miniCartId).length > 0) {
        let isMiniCartOpen = jQuery('#' + miniCartId).hasClass(showClass);
        if (isMiniCartOpen) {
            hideMiniCart();
        } else {
            showMiniCart();
        }
    }
}

function hideMiniCart() {
    let $miniCartDiv = jQuery('#' + miniCartId);
    $miniCartDiv.removeClass(showClass);
    $('.mini-cart-overlay').removeClass('active');
}

function showMiniCart() {
    let $miniCartDiv = jQuery('#' + miniCartId);
    placeMiniCart();
    $miniCartDiv.addClass(showClass);
    $('.mini-cart-overlay').addClass('active');
}

function updateMiniCart(response) {
    let $miniCartDiv = jQuery('#' + miniCartId);
    updateMiniCartLabel(response.qty);
    $miniCartDiv.html(response.html);
}

function blockMiniCart() {
    let $miniCartDiv = jQuery('#' + miniCartId);
    $miniCartDiv.addClass(blockClass + ' ' + loadClass);

    const $label = jQuery('#' + counterMiniCartId);
    $label.addClass(loadClass);
}

function unblockMiniCart() {
    let $miniCartDiv = jQuery('#' + miniCartId);
    $miniCartDiv.removeClass(blockClass + ' ' + loadClass);

    const $label = jQuery('#' + counterMiniCartId);
    $label.removeClass(loadClass);
}

function updateMiniCartLabel(itemCount) {
    let labelItemCount = itemCount;
    if (typeof itemCount !== 'number') {
        labelItemCount = 0;
        // console.warn('Empty itemCount argument or does not exists.');
        return;
    }
    const $count = jQuery('#' + counterMiniCartId);
    $count.text(labelItemCount);
}

function placeMiniCart(options) {
    const $miniCart = jQuery('#' + miniCartId);
    const $miniCartAnchor = jQuery('#' + anchorMiniCartId);
    const $miniCartTrigger = jQuery('#' + triggerMiniCartId);

    // Get param options or default vals
    const { position, fixed } = {
        position: 'bottom center',
        fixed: true,
        ...options,
    };

    // Reset and return if mobile
    // if (jQuery(window).innerWidth() < 768) {
    //     $miniCart.css('top', '');
    //     $miniCart.css('left', '');

    //     return;
    // }

    const $anchor =
        $miniCartAnchor.length > 0
            ? $miniCartAnchor.first()
            : $miniCartTrigger.first();

    if ($anchor.length === 0) {
        console.error('No cart anchor selected');
        return;
    }

    // Get anchor pos
    let anchorPos = $anchor.offset();

    const anchorSize = {
        width: $anchor.innerWidth(),
        height: $anchor.innerHeight(),
    };

    // Define variable for target position
    let targetPos = anchorPos;

    switch (position) {
        case 'bottom left':
            targetPos.top += anchorSize.height + 5;
            break;

        case 'bottom center':
            targetPos.top += anchorSize.height + 5;
            targetPos.left += anchorSize.width;
            break;
    }

    // $miniCart.css(targetPos);
}

export { showMiniCart, getMiniCart };
